import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/Layout.vue'),
    children: [
      {
        path: '',
        name: 'Index',
        component: () => import('@/views/Index.vue')
      },
      {
        path: '/category',
        name: 'Categroy',
        component: () => import('@/views/Category.vue')
      },
      {
        path: '/about',
        name: 'About',
        component: () => import('@/views/About.vue')
      },
      {
        path: '/link',
        name: 'Link',
        component: () => import('@/views/Link.vue')
      }
    ]
  },
  {
    path: '/pdf',
    name: 'Pdf',
    component: () => import('@/views/Pdf.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
